@font-face {
font-family: '__ppeikoFont_a7b6ad';
src: url(/_next/static/media/65ea315b0d06bee9-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__ppeikoFont_a7b6ad';
src: url(/_next/static/media/bb8e132d577ba0ab-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: italic;
}@font-face {font-family: '__ppeikoFont_Fallback_a7b6ad';src: local("Arial");ascent-override: 82.46%;descent-override: 11.24%;line-gap-override: 34.39%;size-adjust: 106.72%
}.__className_a7b6ad {font-family: '__ppeikoFont_a7b6ad', '__ppeikoFont_Fallback_a7b6ad'
}

